import { computed, reactive, ref, type Ref } from 'vue'
import { BridgeService } from '@/lib/api'
import { useServices } from '@/lib/services'
import type { VersionData } from './version.interfaces'
import type { BridgeBaseInfo } from '../base-info/BridgeBaseInfo'
import { usePermissionManager } from '@/modules/Permissions'

export class BridgeVersionManager {
  protected readonly _service: BridgeService = useServices().bridge
  public version: Ref<string> = ref('')
  public latest: Ref<string> = ref('')
  public available: Ref<boolean> = ref(false)
  public compatible: Ref<boolean> = ref(false)
  public current: Ref<string> = ref('')
  public locked: Ref<boolean> = ref(false)
  private readonly _timeout: { timeout: number; delay: number } = reactive({ timeout: 0, delay: 0 })

  public needUpdate = computed(() => this.available.value && this.compatible.value)

  constructor(
    public readonly id: string,
    data: Partial<VersionData>,
    private readonly base: BridgeBaseInfo
  ) {
    const {
      version = '0.0.1',
      compatible = false,
      current = '0.0.0',
      latest = '0.0.0',
      available = false,
      locked = false
    } = data
    this.version.value = version
    this.compatible.value = compatible
    this.current.value = current
    this.latest.value = latest
    this.available.value = available
    this.block(locked)
  }
  public block(state: boolean) {
    this.locked.value = Boolean(state)
  }

  async refetchCounter(timer: number = 120) {
    const timerInSec = timer * 1000
    this._timeout.timeout = setTimeout(() => {
      this.fetchUpdateStatus()
    }, timerInSec) as unknown as number
    this._timeout.delay = timerInSec
  }

  resetCounter() {
    clearTimeout(this._timeout.timeout)
    Object.assign(this._timeout, { timeout: 0, delay: 0 })
  }

  public async fetchUpdateStatus(force: boolean = false): Promise<boolean> {
    if (!usePermissionManager().hasPermission({ action: 'bridgeSoftwareUpdate' })) return false
    if (this.latest.value === '0.0.0' || force) {
      this.resetCounter()
      const { locked, latest, available, compatible, current } = await this._service.updateCheck(
        this.id
      )
      this.current.value = current
      this.latest.value = latest
      this.available.value = available
      this.compatible.value = compatible
      this.version.value = current
      this.block(locked)
    }
    return this.available.value && this.compatible.value
  }

  public async update() {
    if (this._timeout.delay === 0) {
      this.block(true)
      await this._service.softwareUpdate(this.id)
      this.block(false)
      return this.refetchCounter()
    }
  }
}
