import { defineStore } from 'pinia'
import { useServices } from '@/lib/services'
import { ResourceTypes, type ChallengeOrderEntity, type CurrentUser, ApiClient } from '@/lib/api'
import { useApplicationStore } from '@/stores/useApplicationStore'
import { ChallengeType } from '@/lib/api'
import { SESSION_SOCKET_URL } from '@/lib/host'
import router from '@/router'
import { useOnboardingStore } from '@/modules/Onboarding/store/useOnboardingStore'
import { useImpexStore } from '@/stores/import-export/useImpexStore'
import { useCameraStore } from '@/modules/Camera/store/cameraStore'
import { useCustomSettingStore } from '@/stores/custom-setting/useCustomSettingStore'
import { useNotificationStore } from '@/modules/Notifications/store/useNotificationStore'
import { usePaymentStore } from '@/stores/payment/usePaymentStore'
import { usePermissionsStore } from '@/modules/Permissions/'
import { SecuritySessionsStore } from '@/stores/security/Sessions'
import { useTabStore } from '@/stores/tab/useTabStore'
import { useWorkspaceStore } from '@/modules/Workspace/store/useWorkspaceStore'
import { useThemeStore } from '@/stores/Theme/useThemeStore'
import { SessionClient, SocketVersion } from '@/lib/socket/SessionClient'
import { useBridgeStore } from '@/modules/Bridge/store'
import { PlayerRepository, getPlayerRepository } from '@/player/lib/player/player-repository'
import { usePermissionManager } from '@/modules/Permissions'
import * as Sentry from '@sentry/vue'
import { useMonitoringStore } from '@/stores/tab/monitoring'
import { useEventBus } from '@/utils/event-bus/EventBus'
import { useTagStore } from '@/modules/tag/store/TagStore'
import { NotificationDisplayTypes } from '@/modules/Notifications/store'
import { useAlertStore } from '@/stores/alert/useAlertStore'
import { useCameraStoreWrapper } from '@/modules/Monitoring/helpers/cameraStoreWrapper'
import { useCameraStatusStore } from '@/stores/camera/useCameraStatusStore'
import { useBridgeStoreWrapper } from '@/modules/Bridge/helpers/bridgeStoreWrapper'
import { messageType } from '@/components/general/messages/interface'
import { useBridgeCameraWaiter } from '@/modules/Camera/helpers/bridgeCameraWaiter'
import { translateTxt } from '@/modules/i18n'
import { useWorkspaceStatusStore } from '../../modules/Workspace/store/useWorkspaceStatusStore'

const socketVersion = SocketVersion.v1

interface AuthToken {
  accessToken: string | null
  refreshToken: string | null
}

enum UpdatePaymentListenerMethods {
  Method = 'Method',
  WS = 'WS',
  Sub = 'Sub'
}

interface RuleItem {
  id: number
  type: ChallengeType
  status: boolean
}

export enum AuthenticationWorkflowState {
  password = 'password',
  otp = 'otp',
  tOtp = 'tOtp',
  register = 'register',
  success = 'success',
  phone = 'phone'
}

interface AuthenticationState {
  phone: string
  resetPasswordToken: string
  currentUserAvatar: string
  currentUser: CurrentUser | null
  rules: Array<ChallengeOrderEntity>
  disableRules: Array<RuleItem>
  isAdmin: boolean
  activeTabProfile: number
  sessionSocket: SessionClient | null
  rulesLoading: boolean
  socketCloseMessage: any
  isSafeMode: boolean
  loginState: AuthenticationWorkflowState
}

export const useAuthenticationStore = defineStore('Authentication', {
  state: (): AuthenticationState => ({
    phone: '',
    resetPasswordToken: '',
    socketCloseMessage: '',
    currentUserAvatar: '',
    currentUser: null,
    rules: [],
    disableRules: [],
    isAdmin: false,
    activeTabProfile: 0,
    sessionSocket: null,
    rulesLoading: false,
    isSafeMode: false,
    loginState: AuthenticationWorkflowState.phone
  }),

  getters: {
    storage() {
      if (this.isSafeMode) return localStorage
      else return sessionStorage
    }
  },

  actions: {
    async initializeTokens() {
      try {
        this.isSafeMode = !!localStorage.getItem('mode') && localStorage.getItem('mode') === 'safe'
        const accessToken = this.storage.getItem('token')
        const refreshToken = this.isSafeMode ? this.storage.getItem('token') : null
        const expirationDate = this.storage.getItem('expirationDate')
        if (
          accessToken &&
          expirationDate &&
          Number(expirationDate) > Date.now() &&
          ((this.isSafeMode && refreshToken) || !this.isSafeMode)
        )
          this.setAuthorizationToken({
            accessToken,
            refreshToken
          })
        else throw new Error()
      } catch (e) {
        await this.logout()
      }
    },

    async getCurrentUser() {
      this.currentUser = await useServices().authenticationManager.authentication.currentUser()
      Sentry.setUser({
        id: this.currentUser?.user?.id,
        email: this.currentUser?.user?.email,
        username: this.currentUser?.user?.phone
      })
      return this.currentUser
    },

    async loadCurrentUserAvatar() {
      const image = await useServices().authenticationManager.user.currentAvatar()
      if (image.data.size > 0) {
        const reader = new FileReader()
        reader.addEventListener(
          'load',
          () => {
            this.currentUserAvatar = reader.result as string
          },
          false
        )
        reader.readAsDataURL(image.data)
      }
    },

    async updateUserAvatar(image: FormData) {
      await useServices().authenticationManager.user.avatarUpdate(image)
      this.loadCurrentUserAvatar()
    },
    async removeUserAvatar() {
      await useServices().authenticationManager.user.avatarRemove()
      this.currentUserAvatar = ''
    },

    async checkUpdatingAction(attempt = 0) {
      await new Promise((r) => setInterval(r, 2000))
      if (ApiClient.isRunning.value) {
        attempt++
        if (attempt === 1) {
          const eventBus = useEventBus()
          eventBus.emit('waitForDoActions')
        }
        await this.checkUpdatingAction(attempt)
      }
    },

    async logout() {
      if (ApiClient.exitCheck()) {
        try {
          useApplicationStore().startLoading('logout')
          await this.checkUpdatingAction()
          this.storage.removeItem('expirationDate')
          this.storage.removeItem('mode')
          this.storage.removeItem('token')
          this.storage.removeItem('refresh')
          this.storage.removeItem('workspaceId')
          this.storage.removeItem('activeTab')
          usePermissionManager().clearPermissions()
          if (this.currentUser && this.currentUser.session)
            await useServices().authenticationManager.session.remove(this.currentUser.session.id)
          this.resetAllStore(true)
          useServices().removeHeader('Authorization')
        } finally {
          await router.push({ name: 'authentication' })
          useApplicationStore().stopLoading('logout')
        }
      }
    },
    setAuthorizationToken(tokens: AuthToken) {
      const service = useServices()
      if (tokens['refreshToken']) this.setSafeToken(tokens)
      else this.setNormalToken(tokens)

      service.setHeader('Authorization', 'Bearer ' + tokens['accessToken'])
    },
    setSafeToken(tokens: AuthToken): void {
      if (tokens['accessToken']) {
        this.isSafeMode = true
        localStorage.setItem('expirationDate', String(this.calculateExpirationDate()))
        localStorage.setItem('mode', 'safe')
        localStorage.setItem('token', tokens['accessToken'])
        localStorage.setItem('refresh', tokens['refreshToken'] ? tokens['refreshToken'] : '')
      }
    },
    setNormalToken(tokens: AuthToken): void {
      if (tokens['accessToken']) {
        this.isSafeMode = false
        sessionStorage.setItem('expirationDate', String(this.calculateExpirationDate()))
        sessionStorage.setItem('mode', 'normal')
        sessionStorage.setItem('token', tokens['accessToken'])
      }
    },
    calculateExpirationDate(): number {
      return Date.now() + 24 * 3550 * 1000
    },

    async openSessionSocket() {
      this.sessionSocket = new SessionClient(SESSION_SOCKET_URL, socketVersion)
      try {
        await this.sessionSocket.start()
        this.takeOverListener()
        this.updatePermissionsListener()
        this.updateUsersListener()
        this.updateWorkspaceUsersListener()
        this.ditachUserListener()
        this.lockedWorkspaceListener()
        this.updatePaymentListener()
        this.updateNotificationListener()
        this.updateResourceListener()
        this.removeResourceListener()
        this.updateMaskListener()
        this.updateCameraListener()
        this.bridgeSoftwareUpdateListener()
        this.bridgeCameraCreationsListener()
        this.bridgeStartUpdateListener()
        this.handleUpdateEvent()
      } catch (e) {
        console.log(e)
      }
    },

    handleUpdateEvent() {
      if (this.sessionSocket) {
        this.sessionSocket.on('UpdateEvent', async (data: any) => {
          await useAlertStore().loadSchedules()
        })
      }
    },

    takeOverListener(): void {
      if (socketVersion === SocketVersion.v1) {
        if (this.sessionSocket)
          this.sessionSocket.on('SessionTakeOver', async (data) => {
            this.socketCloseMessage = {
              os: data.metadata.uaOs,
              browser: data.metadata.uaBrowser,
              ip: data.metadata.ip
            }
            await router.push({ name: 'loginAwayError' })
          })
      } else {
        if (this.sessionSocket)
          this.sessionSocket.on('takeover', async (data) => {
            this.socketCloseMessage = data
            await router.push({ name: 'loginAwayError' })
          })
      }
    },

    bridgeStartUpdateListener() {
      if (this.sessionSocket)
        this.sessionSocket.on('BridgeUpdateStart', async (data: any) => {
          const bridgeId = data.metadata.deviceId
          const bridge = useBridgeStore().getFetchedBridge(bridgeId)
          if (bridge) {
            bridge.base.versionManager.fetchUpdateStatus(true)
            useCameraStore().blockCameras(bridgeId, true)
          }
        })
    },

    bridgeCameraCreationsListener() {
      const bridgeCameraWaiter = useBridgeCameraWaiter()

      if (this.sessionSocket) {
        this.sessionSocket.on('BridgeCamCreationSucceed', async (data: any) => {
          const cameraId = data.metadata.remoteId
          bridgeCameraWaiter.onCreated(cameraId)
        })

        this.sessionSocket.on('BridgeCamCreationFailed', async (data: any) => {
          const cameraId = data.metadata.remoteId
          const cameraName = data.metadata.name
          bridgeCameraWaiter.onFailed(cameraId, cameraName)
        })
      }
    },

    bridgeSoftwareUpdateListener() {
      async function updateHandler(bridgeId: string) {
        const bridge = useBridgeStore().getFetchedBridge(bridgeId)
        if (bridge) {
          await bridge.base.versionManager.fetchUpdateStatus(true)
          bridge.base.versionManager.block(false)
        }
      }

      function showToast(toast) {
        const eventBus = useEventBus()
        eventBus.emit('toast.add', toast)
      }

      if (this.sessionSocket)
        this.sessionSocket.on('BridgeUpdateCancelled', (data: any) => {
          const bridgeId = data.metadata.deviceId
          updateHandler(bridgeId)
          showToast({
            severity: messageType.error,
            detail: translateTxt('bridge.errorMessage.softwareUpdateCanceled'),
            life: 5000
          })
        })

      if (this.sessionSocket)
        this.sessionSocket.on('BridgeUpdateFailed', (data: any) => {
          const bridgeId = data.metadata.deviceId
          updateHandler(bridgeId)
          showToast({
            severity: messageType.error,
            detail: translateTxt('bridge.errorMessage.softwareUpdateFailed'),
            life: 5000
          })
        })

      if (this.sessionSocket)
        this.sessionSocket.on('BridgeUpdateSucceed', (data: any) => {
          const bridgeId = data.metadata.deviceId
          updateHandler(bridgeId)
          showToast({
            severity: messageType.success,
            detail: translateTxt('bridge.drawer.update.update.updateSuccessfully'),
            life: 5000
          })
        })
    },

    async updateResourceListener() {
      if (this.sessionSocket)
        this.sessionSocket.on('ResourceEvent', async (data: any) => {
          const type = data.metadata.type as ResourceTypes
          const remoteId: string = data.metadata.remoteId
          const currentWorkspace = useWorkspaceStore().currentWorkspace

          switch (type) {
            case ResourceTypes.Camera: {
              try {
                const camera = await useCameraStore().fetchCamera(remoteId)
                getPlayerRepository().updateInformation(camera.cameraObject)
              } catch (e) {
                // Safe catch
              }
              break
            }
            case ResourceTypes.Bridge:
              try {
                const bridge = useBridgeStore().getFetchedBridge(remoteId)
                if (bridge) {
                  bridge.getBridgeData()
                } else {
                  useBridgeStore().fetchBridge(remoteId, true)
                }
              } catch (e) {
                // Safe catch
              }
              break
            case ResourceTypes.Workspace:
              useWorkspaceStore().getCurrentWorkspace()
              if (currentWorkspace) usePermissionsStore().getUsers(currentWorkspace.id)
              break
            case ResourceTypes.Bookmark:
              PlayerRepository.refreshBookmarksForAllPlayers()
              break
            default:
              break
          }
        })
    },

    async removeResourceListener() {
      if (this.sessionSocket)
        this.sessionSocket.on('ResourceRemoved', async (data: any) => {
          const type = data.metadata.type as ResourceTypes
          const remoteId: string = data.metadata.remoteId
          const currentWorkspace = useWorkspaceStore().currentWorkspace

          switch (type) {
            case ResourceTypes.Camera: {
              try {
                await useCameraStore().deleteCamera(remoteId)
              } catch (e) {
                // Safe catch
              }
              break
            }
            case ResourceTypes.Bridge:
              try {
                useBridgeStore().removeFromList(remoteId)
              } catch (e) {
                // Safe catch
              }
              break
            case ResourceTypes.Workspace:
              useWorkspaceStore().getCurrentWorkspace()
              if (currentWorkspace) usePermissionsStore().getUsers(currentWorkspace.id)
              break
            case ResourceTypes.Bookmark:
              PlayerRepository.refreshBookmarksForAllPlayers()
              break
            default:
              break
          }
        })
    },

    updateMaskListener() {
      if (this.sessionSocket)
        this.sessionSocket.on('MaskAltered', async (data: any) => {
          const cameraId = data.metadata.remoteId
          PlayerRepository.refreshMask(cameraId)
          setTimeout(async () => {
            await useCameraStore().getCameraFetched(cameraId)?.base.reload()
            useCameraStore().getCameraFetched(cameraId)?.thumbnail.loadThumbnail()
          })
        })
    },

    updateNotificationListener(): void {
      if (this.sessionSocket)
        this.sessionSocket.on('Notification', async (data) => {
          const id = data.metadata.id
          await useNotificationStore().fetchNewNotification(id)
        })
    },

    updatePaymentListener(): void {
      if (this.sessionSocket)
        this.sessionSocket.on('BillingEvent', async (values) => {
          const method: UpdatePaymentListenerMethods = values.scope as UpdatePaymentListenerMethods
          switch (method) {
            case UpdatePaymentListenerMethods.WS:
              await Promise.all([
                usePaymentStore().loadCurrentStripeWorkspace(true),
                usePaymentStore().getWorkspaceSubscriptions(true),
                useWorkspaceStatusStore().fetchStatuses(true)
              ])
              break
            case UpdatePaymentListenerMethods.Method:
              await usePaymentStore().loadPaymentMethods(true)
              break
            case UpdatePaymentListenerMethods.Sub:
              await usePaymentStore().loadActiveSubscription(true)
              await useBridgeStoreWrapper().loadBridges()
              await useCameraStoreWrapper().loadCameras()
              break
          }
        })
    },

    updatePermissionsListener(): void {
      if (this.sessionSocket)
        this.sessionSocket.on('PermissionMatrixUpdated', async () => {
          await this.reloadPermissions()
          await Promise.all([
            useTabStore().checkTabsPermission(),
            usePaymentStore().fetchPaymentInformation()
          ])
        })
    },

    updateWorkspaceUsersListener(): void {
      if (this.sessionSocket)
        this.sessionSocket.on('WorkspaceUserEvent', async (data) => {
          await Promise.all([
            usePermissionsStore().getUsers(data.metadata.workspaceId, true),
            usePermissionsStore().getAllPermissions(true)
          ])
        })
    },

    updateCameraListener() {
      if (this.sessionSocket)
        this.sessionSocket.on('Camera', async (data: any) => {
          if (data.scope === 'Status') {
            useCameraStatusStore().onCameraStatusUpdate(
              data.metadata.cameraId,
              data.metadata.status
            )
          }
        })
    },

    updateUsersListener(): void {
      if (this.sessionSocket)
        this.sessionSocket.on('UserUpdated', async (data: any) => {
          await Promise.all([
            usePermissionsStore().getSingleActiveUser(data.metadata.userId),
            usePermissionsStore().getAllPermissions(true)
          ])
          usePermissionsStore().getUsersAvatar(data.metadata.userId)
        })
      if (this.sessionSocket)
        this.sessionSocket.on('OnlineStatusUpdated', async (data: any) => {
          await usePermissionsStore().getSingleActiveUser(data.metadata.userId)
        })
    },
    ditachUserListener(): void {
      if (this.sessionSocket)
        this.sessionSocket.on('UserDetached', async (data: any) => {
          const wsId = data.metadata.workspaceId
          const userId = data.metadata.userId
          if (
            useWorkspaceStore().currentWorkspace?.id === wsId &&
            this.currentUser?.user?.id === userId
          ) {
            await this.logout()
            window.location.reload()
          } else {
            usePermissionsStore().userDeleteMessage(userId)
          }
        })
    },

    lockedWorkspaceListener(): void {
      if (this.sessionSocket)
        this.sessionSocket.on('WorkspaceLocked', async (data: any) => {
          if (
            useWorkspaceStore().currentWorkspace?.id === data.metadata.workspaceId &&
            this.currentUser?.user?.id != data.metadata.ownerId
          ) {
            this.logout()
          }
        })
    },

    async isSessionActive() {
      const { active } = await useServices().userEventBus.checkUserSessionStatus()
      return active
    },

    closeSessionSocket() {
      if (this.sessionSocket) this.sessionSocket.stop()
    },

    async loadPermissions() {
      const userId = this.currentUser?.user?.id
      if (userId) {
        const permissionManager = usePermissionManager()
        await permissionManager.fetchPermissions(userId)
        permissionManager.recheckComponents()
      }
    },

    async reloadPermissions() {
      const userId = this.currentUser?.user?.id
      if (userId) {
        const permissionManager = usePermissionManager()
        await permissionManager.refetchPermissions(userId)
        permissionManager.recheckComponents()
      }
    },

    async reopenSessionSocket() {
      this.closeSessionSocket()
      await this.openSessionSocket()
    },

    async getRules(force: boolean = false) {
      if (this.rules.length === 0 || force) {
        this.rulesLoading = true
        const defaultRules = [
          {
            id: 1,
            type: ChallengeType.password,
            status: false,
            step: 1
          },
          {
            id: 2,
            type: ChallengeType.otp,
            status: false,
            step: 2
          },
          {
            id: 3,
            type: ChallengeType.tOtp,
            status: false,
            step: 3
          }
        ]
        this.rules = await useServices().authenticationManager.challenge.findAll()
        this.disableRules = defaultRules.filter(
          (item) => !this.rules.find((rule) => rule.type === item.type)
        )
        this.rulesLoading = false
      }
      return this.rules
    },
    async activateEasyLogin() {
      await useServices().authenticationManager.challenge.activateEasyLogin()
      await this.getRules(true)
    },
    async deactivateEasyLogin() {
      await useServices().authenticationManager.challenge.inactivateEasyLogin()
      await this.getRules(true)
    },
    toggleIsAdmin() {
      this.isAdmin = !this.isAdmin
    },
    resetAllStore(resetAuth: boolean = true) {
      if (resetAuth) {
        this.closeSessionSocket()
        this.$reset()
      }
      useApplicationStore().isReset = true
      useAlertStore().reset()
      useCameraStatusStore().reset()
      useCameraStore().reset()
      useBridgeStore().reset()
      useCustomSettingStore().$reset()
      useOnboardingStore().$reset()
      useImpexStore().$reset()
      useNotificationStore().$reset()
      usePaymentStore().reset()
      usePermissionsStore().$reset()
      SecuritySessionsStore().$reset()
      useTabStore().$reset()
      useWorkspaceStore().$reset()
      useWorkspaceStatusStore().reset()
      useThemeStore().$reset()
      useMonitoringStore().$reset()
      useTagStore().reset()
    }
  }
})
