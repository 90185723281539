import { Service } from '@/lib/api'
import type {
  ChatEntity,
  UserColorDto,
  UserDateDto,
  UserFunctionCallResultDto,
  UserMessageDto,
  UserObjectDto
} from '@/lib/api/services/ai/chat/chat.interface'

export class ChatService extends Service {
  // chat version 2
  public create(cameraId: string, timeZone: string) {
    return this.post<ChatEntity>('/api/event-storage-ai/chat-v2', {
      cameraId,
      timeZone
    })
  }

  public createUserMessage(body: UserMessageDto) {
    return this.post<ChatEntity>('/api/event-storage-ai/chat-v2/message/text', body)
  }

  public createManualActionDate(body: UserDateDto) {
    return this.post<ChatEntity>('/api/event-storage-ai/chat-v2/message/date', body)
  }

  public createManualActionColor(body: UserColorDto) {
    return this.post<ChatEntity>('/api/event-storage-ai/chat-v2/message/color', body)
  }

  public createManualActionObject(body: UserObjectDto) {
    return this.post<ChatEntity>('/api/event-storage-ai/chat-v2/message/object', body)
  }

  public submitFunctionData(body: UserFunctionCallResultDto) {
    return this.post<ChatEntity>('/api/event-storage-ai/chat-v2/message/function-call', body)
  }

  public findOne(id: string) {
    return this.get<ChatEntity>(`/api/event-storage-ai/chat-v2/${id}`)
  }
  public async findLastChat(cameraId: string) {
    // i strongly believe that if there is no chat, the backend should return 200 with error message plus an empty result not 404!!!!
    try {
      return await this.get<ChatEntity>(`/api/event-storage-ai/chat-v2/camera/${cameraId}/latest`)
    } catch (error: any) {
      if (error === 'Not Found') {
        throw {
          statusCode: 404
        }
      }
    }
  }
  public RemoveChat(id: string) {
    return this.delete<ChatEntity>(`/api/event-storage-ai/chat-v2/${id}`)
  }
}
