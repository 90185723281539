import type { Camera as CameraObject, CameraReturnType, CameraService } from '@/lib/api'
import { RecordMode } from '@/lib/api'
import { CameraCore } from '@/modules/Camera/CameraCore'
import { SyncAbleObject } from '@/modules/Camera/SyncAbleObject'
import type { CameraCoreComposite, CameraRecordInformation } from '@/modules/Camera/interface'
import { useServices } from '@/lib/services'
import { getPlayerRepository } from '@/player/lib/player/player-repository'

export class CameraRecord
  extends SyncAbleObject<CameraRecordInformation>
  implements CameraCoreComposite
{
  public readonly id: string
  protected service: CameraService = useServices().camera

  constructor(
    public readonly core: CameraCore,
    camera: CameraObject
  ) {
    super(CameraRecord.parseCameraObject(camera))
    this.id = camera.id
  }

  protected static parseCameraObject(camera: CameraObject): CameraRecordInformation {
    return {
      availableArchiveAges: camera.recordInformation.availableArchiveAges,
      availableModes: camera.recordInformation.availableModes,
      archiveAge: camera.recordInformation.archiveAge,
      mode: camera.recordInformation.mode,
      pausedAt: camera.recordInformation.pausedAt
        ? new Date(camera.recordInformation.pausedAt)
        : new Date()
    }
  }

  setMode(mode: RecordMode): void {
    if (!this.core.locked) this.data.mode = mode
  }

  public pause() {
    this.setMode(RecordMode.pause)
    return this.update()
  }

  public resume() {
    this.setMode(RecordMode.always)
    return this.update()
  }

  public resumeMotion() {
    this.setMode(RecordMode.HWMotion)
    return this.update()
  }

  public async update() {
    if (!this.core.locked) {
      const data: CameraReturnType = await this.service.updateRecordMode(this.id, {
        recordMode: this.data.mode
      })
      this.sync()
      this.updateDependencies(data)
      await this.updateStatus()
      this.updateCameraObject(data)
    }
  }

  protected async updateCameraObject(camera: CameraReturnType) {
    this.core.updateCameraObject(camera)
  }

  protected async updateStatus() {
    await this.core.status.update(true)
  }

  public updateDependencies(camera: CameraReturnType) {
    Object.assign(this.data, CameraRecord.parseCameraObject(camera as CameraObject))
  }
}
