import type { Notification, NotificationFindDto } from './'
import { Service } from '@/lib/api'

export class NotificationService extends Service {
  public list(limit: string, offset: string) {
    return this.get<Notification[]>(`/api/notifier/notification?limit=${limit}&offset=${offset}`)
  }

  public find(id: string) {
    return this.get<Notification>(`/api/notifier/notification/${id}`)
  }

  public markAsRead(id: string) {
    // docent exists
    return this.patch<Notification[]>(`/api/notifier/notification/${id}`)
  }

  public markAsCleared(id: string) {
    return this.patch<Notification[]>(`/api/notifier/notification/clear/${id}`)
  }

  public markAsClearedAll() {
    return this.patch<Notification[]>(`/api/notifier/notification/clear`)
  }

  public unread(limit: string, offset: string) {
    return this.get<Notification[]>(
      `/api/notifier/notification/unread?limit=${limit}&offset=${offset}`
    )
  }

  public findAdvance(config: NotificationFindDto, page = 1, ids?: Array<string>) {
    return this.post<Notification[]>( // 166
      `/api/notifier/notification/find?page=${page}`,
      { ...config, ids }
    )
  }

  public sliderText(id: string) {
    return this.get<Notification[]>(`/api/notifier/notification/note/${id}`)
  }
}
