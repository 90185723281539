<script setup lang="ts"></script>
<template>
  <div class="loader-container">
    <div class="outer-circle">
      <div class="inner-circle"></div>
    </div>
  </div>
</template>

<style lang="scss">
.light {
  --app-loading-background-color: #edf0f6;
  --app-loading-circle-color: #acb1be;
}

.dark {
  --app-loading-background-color: #0e141a;
  --app-loading-circle-color: #fff;
}
</style>

<style scoped lang="scss">
$outer-circle-size: 150;
$gap: 40;
$inner-circle-size: $outer-circle-size - $gap;

@function posAtCenter($iSize) {
  @return calc(50% - #{calc($iSize / 2)}px);
}

@mixin circle-size($iSize) {
  width: $iSize + px;
  height: $iSize + px;
}

.loader-container {
  width: 100vw;
  height: 100vh;
  margin: 0;
  background-color: var(--app-loading-background-color);
}

.loader-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.outer-circle {
  position: absolute;
  top: posAtCenter($outer-circle-size);
  left: posAtCenter($outer-circle-size);

  @include circle-size($outer-circle-size);
  background-color: var(--app-loading-circle-color);
  border-radius: 50%;
  box-shadow: 0px 0px 0px var(--app-loading-circle-color);

  animation: animateOuterCircle cubic-bezier(0.4, 0, 0.2, 1) 2s infinite;
}

.inner-circle {
  position: absolute;
  top: posAtCenter($inner-circle-size);
  left: posAtCenter($inner-circle-size);

  @include circle-size($inner-circle-size);
  background-color: var(--app-loading-background-color);
  border-radius: 50%;
  box-shadow: 0px 0px 0px var(--app-loading-background-color) inset;
  animation: animateInnerCircle cubic-bezier(0.4, 0, 0.2, 1) 2s infinite;
}

@keyframes animateOuterCircle {
  0% {
    @include circle-size($outer-circle-size - $gap);
    top: posAtCenter($outer-circle-size - $gap);
    left: posAtCenter($outer-circle-size - $gap);
    box-shadow: 0px 0px 0px var(--app-loading-circle-color);
  }
  45% {
    @include circle-size($outer-circle-size);
    top: posAtCenter($outer-circle-size);
    left: posAtCenter($outer-circle-size);
    box-shadow:
      0px 0px 10px var(--app-loading-circle-color),
      0px 0px 12px var(--app-loading-circle-color);
  }
  90% {
    @include circle-size($outer-circle-size);
    top: posAtCenter($outer-circle-size);
    left: posAtCenter($outer-circle-size);
    box-shadow: 0px 0px 0px var(--app-loading-circle-color);
  }
  100% {
    @include circle-size($outer-circle-size - $gap);
    top: posAtCenter($outer-circle-size - $gap);
    left: posAtCenter($outer-circle-size - $gap);
    box-shadow: 0px 0px 0px var(--app-loading-circle-color);
  }
}

@keyframes animateInnerCircle {
  0% {
    @include circle-size($inner-circle-size + 5px);
    top: posAtCenter($inner-circle-size + 5px);
    left: posAtCenter($inner-circle-size + 5px);
    box-shadow: 0px 0px 0px var(--app-loading-background-color) inset;
  }
  45% {
    @include circle-size($inner-circle-size);
    top: posAtCenter($inner-circle-size);
    left: posAtCenter($inner-circle-size);
    box-shadow:
      0px 0px 4px var(--app-loading-circle-color) inset,
      0px 0px 8px var(--app-loading-circle-color) inset;
  }
  99% {
    @include circle-size($inner-circle-size + 70);
    top: posAtCenter($inner-circle-size + 70);
    left: posAtCenter($inner-circle-size + 70);
    box-shadow: 0px 0px 0px var(--app-loading-background-color) inset;
  }
  100% {
    @include circle-size($inner-circle-size + 5px);
    top: posAtCenter($inner-circle-size + 5px);
    left: posAtCenter($inner-circle-size + 5px);
    box-shadow: 0px 0px 0px var(--app-loading-background-color) inset;
  }
}
</style>
