import { useServices } from '@/lib/services'
import {
  type Camera as CameraObject,
  type UpdateCameraData,
  CameraService,
  ResourceTypes
} from '@/lib/api'
import { SyncAbleObject } from '@/modules/Camera/SyncAbleObject'
import type { CameraBaseObject } from '@/modules/Camera/interface'
import { CameraLocation } from '../location/CameraLocation'
import { MetadataManager } from '@/modules/metadata/lib/MetadataManager'
import { markRaw } from 'vue'
import sortObjectByKeys from '@/utils/sortObject/byKeys'
import type { CameraCore } from '@/modules/Camera/CameraCore'

export class CameraBaseInfo extends SyncAbleObject<CameraBaseObject> {
  protected service: CameraService = useServices().camera
  public readonly locationManager: CameraLocation
  public readonly metadataManager: MetadataManager
  public readonly id: string
  public locked: boolean = false

  constructor(
    public readonly core: CameraCore,
    cameraObject: CameraObject
  ) {
    super(CameraBaseInfo.parseCameraObject(cameraObject))
    this.id = core.id
    this.locationManager = markRaw(new CameraLocation(this, this.id))
    this.metadataManager = markRaw(new MetadataManager(this, this.id, ResourceTypes.Camera))
  }

  protected static parseCameraObject(camera: CameraObject): CameraBaseObject {
    return {
      id: camera.id,
      workspaceId: camera.workspaceId,
      enable: camera.enable,
      configured: camera.configured,
      name: camera.name,
      description: camera.description ? camera.description : '',
      timezone: camera.timezone ? camera.timezone : '',
      maskData: camera.maskData || undefined,
      updatedAt: new Date(camera.updatedAt),
      createdAt: new Date(camera.createdAt)
    }
  }

  public async update(data: UpdateCameraData) {
    if (!this.locked) {
      await this.updateCamera(data)
      super.sync()
      this.updateDependencies()
    }
  }

  protected async updateCamera(data: UpdateCameraData): Promise<void> {
    const newData = JSON.stringify(sortObjectByKeys(data))
    const oldData = JSON.stringify(sortObjectByKeys(this.data))
    if (oldData !== newData) {
      const cameraUpdateData = await this.service.update(this.id, data)
      this.initialCameraData(cameraUpdateData)
    }
  }

  public initialCameraData(cameraObject: CameraObject) {
    Object.assign(this.data, CameraBaseInfo.parseCameraObject(cameraObject))
    this.core.updateCameraObject(this.data)
  }

  protected updateDependencies() {
    //todo
  }

  public async reload() {
    const data = await useServices().camera.find(this.id)
    this.initialCameraData(data)
  }

  public block(state: boolean): void {
    this.locked = state
  }
}
