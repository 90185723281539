export enum ObjectType {
  person,
  bicycle,
  car,
  motorcycle,
  // airplane,
  // bus,
  // train,
  truck = 7,
  // boat,
  // trafficLight,
  // fireHydrant,
  // stopSign,
  // parkingMeter,
  // bench,
  // bird,
  cat = 15,
  dog,
  // horse,
  // sheep,
  // cow,
  // elephant,
  // bear,
  // zebra,
  // giraffe,
  bag = 24 // backpack
  // umbrella,
  // handbag = 26 // handbag
  // tie,
  // suitcase,
  // frisbee,
  // skis,
  // snowboard,
  // sportsBall,
  // kite,
  // baseballBat,
  // baseballGlove,
  // skateboard,
  // surfboard,
  // tennisRacket,
  // bottle,
  // wineGlass,
  // cup,
  // fork,
  // knife,
  // spoon,
  // bowl,
  // banana,
  // apple,
  // sandwich,
  // orange,
  // broccoli,
  // carrot,
  // hotDog,
  // pizza,
  // donut,
  // cake,
  // chair,
  // couch,
  // pottedPlant,
  // bed,
  // diningTable,
  // toilet,
  // tv,
  // laptop,
  // mouse,
  // remote,
  // keyboard,
  // cellPhone,
  // microwave,
  // oven,
  // toaster,
  // sink,
  // refrigerator,
  // book,
  // clock,
  // vase,
  // scissors,
  // teddyBear,
  // hairDrier,
  // toothbrush
}

export enum Age {
  unknown = -1,
  child,
  teen,
  young_adult,
  adult,
  elder
}

export enum Pose {
  unknown = -1,
  sitting, // tmp, for implementation
  bending, // tmp, for implementation
  standing, // tmp, for implementation
  walking, // tmp, for implementation
  running // tmp, for implementation
} // 12 items

export enum SearchClothType {
  upperBody,
  lowerBody,
  reserve,
  all
}

export enum Color {
  unknown = 0,
  black,
  red,
  green,
  blue,
  yellow,
  pink,
  purple,
  sky_blue,
  // navy_blue,
  // gold,
  // cyan,
  // magenta,
  // teal,
  orange = 14,
  // olive,
  // dark_teal,
  // mustard,
  // maroon,
  // lime_green,
  // dark_green,
  // dark_purple,
  brown = 22,
  // dark_blue,
  // light_blue,
  white = 25,
  gray
}

export enum ClothType {
  upperBody,
  lowerBody,
  reserve
}

export enum Sex {
  female,
  male
}

export interface SearchClothDto {
  type: SearchClothType

  colors: number[]
}

export interface SearchHumanDto {
  age?: Age

  pose?: Pose[]

  clothes?: SearchClothDto[]
}

export interface SearchObjectDto {
  objectType: ObjectType

  color?: Color[]
}

export interface SearchTrackDto {
  cameraId: string

  objects?: SearchObjectDto[]

  humans?: SearchHumanDto[]

  date: string

  paginating?: boolean

  minimumAccuracy?: number
}

export interface ClothDto {
  type: ClothType

  color: Color[]
}

export interface PersonalDetailsDto {
  clothing?: ClothDto[] //upper body , lower body, reserve

  pose?: Pose

  sex?: Sex

  age?: Age
}

export interface ObjectPositionDto {
  x1: number
  y1: number
  x2: number
  y2: number
  dt: number // difference time
}

export interface TrackEntity {
  cameraId: string
  trackId: string
  objectType: ObjectType
  objectTypeConfidence: number
  color: Color[]
  colorConfidence: number[]
  finished: boolean
  actualStartTime: number
  startTime: number
  endTime: number
  objectPosition: Array<ObjectPositionDto>
}

export interface TrackerState {
  cameraId?: string
  tracks: TrackEntity[]
  colors?: ColorObject[]
  type?: ObjectType
  date: Date
  lastTrackDate?: Date
  trackLoading: boolean
  noMoreDate: boolean
  colorOverlay: boolean
  minimumAccuracy: number
  conversationId?: string | undefined
}

export interface MergeObject {
  track?: TrackEntity
  nextTracks: TrackEntity[]
}
export interface ColorObject {
  value: Color
  color: string
  label: string
}

export interface FindTracksConfig {
  criteria: Array<{
    objectType: string
    objectColors: string[]
  }>
  date: string
}
