import { ref, onMounted, onBeforeUnmount } from 'vue'
import type { Team, WorkSpaceUser } from '@/lib/api'
import { useServices } from '@/lib/services'
import { i18n } from '@/lib/api/errors/messageI18/i18n'

export function useIsMobile() {
  const isMobile = ref(false)
  const checkScreenSize = () => {
    isMobile.value = window.innerWidth <= 640 // You can set your breakpoint here
  }

  onMounted(() => {
    window.addEventListener('resize', checkScreenSize)
    checkScreenSize() // Initial check
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', checkScreenSize)
  })

  return {
    isMobile
  }
}

export function transformAndMapUserList(userList: WorkSpaceUser[], teamList: Team[]) {
  return userList.map((item) => {
    // Find corresponding team based on position
    const foundItem = teamList.find((element) => element.id === item.id)

    // Create a new object based on the original item
    const newItem = { ...item }

    // Replace position with teamId if a match was found
    if (foundItem) {
      newItem['teamId'] = foundItem.id
      delete newItem.position
    }

    // Add type based on whether 'phone' exists in the object keys
    // newItem['type'] = Object.keys(newItem).includes('phone') ? typeInvite.phone : typeInvite.email;
    newItem['type'] = Object.keys(newItem).includes('phone') ? 1 : 2

    return newItem
  })
}
export function userAvatar(fullName: string) {
  return fullName
    ?.split(' ')
    .map((name, index) => {
      if (index < 2) {
        return name[0]
      }
    })
    .join('')
    .toUpperCase()
}
export function UserFirstName(fullName: string) {
  return fullName?.split(' ')[0]
}
export function translateError(message: string) {
  const translation = i18n.global.t(message)
  const translationErrors = i18n.global.t(`errorMessage.${message}`)
  return translation != message
    ? translation
    : translationErrors != `errorMessage.${message}`
      ? translationErrors
      : translation
}
export function ConvertError(message, inline: boolean = false): string {
  if (typeof message === 'string') {
    return message.includes('object') ? translateError('tooMany') : translateError(message)
  }

  if (message.message) {
    return message.message
  }

  const [key, value] = Object.entries(message)[0]

  if (typeof value === 'string') {
    return entryToSstring(key, value, inline)
  }

  if (typeof value === 'object' && value != null) {
    if (Object.keys(value).includes('ip')) {
      return translateError('tooMany')
    }

    const valueEntries = Object.entries(value)
    if (valueEntries.length >= 1) {
      const [nestedKey, nestedValue] = valueEntries[0]
      return entryToSstring(key, nestedValue, inline)
    }

    return translateError('oop')
  }

  if (Array.isArray(value)) {
    return entryToSstring(key, value.map(translateError).join(', '), inline)
  }

  return translateError('oop')
}

function entryToSstring(key: string, value: string, inline: boolean = false): string {
  return inline ? translateError(value) : `${translateError(key)} : ${translateError(value)}`
}

export async function isPasswordSecure(password: string): Promise<boolean> {
  const userService = useServices().authenticationManager.user
  const { score } = await userService.passwordStrengthCheckDto(password)
  return score > 3
}
