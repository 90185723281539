import { useServices } from '@/lib/services'
import {
  type CreateCustomerSettingData,
  type CreateCustomerSettingResponse,
  CustomSettingsType
} from '@/lib/api'
import { type LayoutBoxConfig } from '@/player/interfaces'
import { useCameraStoreWrapper } from './cameraStoreWrapper'
import { computed, ref, type Ref } from 'vue'

export function useLayoutStoreWrapper() {
  const service = useServices().customSetting
  const keyIdMap: Map<string, string> = new Map()
  let promisingInProcess: Ref<Promise<any> | undefined> = ref(undefined)

  const loading = computed(() => promisingInProcess.value !== undefined)

  function updateKeyIdMap(response: CreateCustomerSettingResponse) {
    keyIdMap.set(response.key, response.id)
    return Array.isArray(response.value) ? response.value : response.value.data
  }

  function createId(length = 10) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let randomString = ''
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      randomString += characters[randomIndex]
    }
    return randomString
  }

  function apiSerializer(id: string, value: LayoutBoxConfig[]) {
    return {
      key: `layout:${id}`,
      type: CustomSettingsType.userWorkspace,
      value: {
        data: value,
        createdAt: Date.now()
      }
    }
  }

  function apiDeserializer(res: CreateCustomerSettingData): {
    id: string
    value: Array<LayoutBoxConfig>
  } {
    return {
      id: res.key.replace('layout:', ''),
      value: res.value.data
    }
  }

  function create(id: string, value: LayoutBoxConfig[] = []): Promise<LayoutBoxConfig[]> {
    return service.create(apiSerializer(id, value)).then(updateKeyIdMap)
  }

  function removeExtraSettings(data: CreateCustomerSettingResponse[]) {
    if (data.length > 1) {
      for (let i = 1; i < data.length; i++) {
        service.remove(data[i].id)
      }
    }
  }

  function load(id: string): Promise<LayoutBoxConfig[]> {
    return service
      .find({
        key: `layout:${id}`,
        type: CustomSettingsType.userWorkspace
      })
      .then((res) => {
        res.sort((a, b) => (a.value?.createdAt - b.value?.createdAt < 0 ? 1 : -1))
        if (res.length > 1) {
          removeExtraSettings(res)
        }
        if (res[0]) {
          return updateKeyIdMap(res[0])
        } else return undefined
      })
  }

  async function getLayoutServiceId(id: string): Promise<string> {
    if (!keyIdMap.has(`layout:${id}`)) {
      await load(id)
    }
    const csId = keyIdMap.get(`layout:${id}`) || ''
    return csId
  }
  async function update(id: string, value: LayoutBoxConfig[]): Promise<LayoutBoxConfig[]> {
    const csId = await getLayoutServiceId(id)
    if (promisingInProcess.value !== undefined) {
      return promisingInProcess.value
    } else if (!useCameraStoreWrapper().loading.value) {
      promisingInProcess.value = updatePromise(csId, id, value).then(() => {
        promisingInProcess.value = undefined
      })
      return promisingInProcess.value
    }
    return value
  }

  async function updatePromise(
    csId: string,
    id: string,
    value: Array<LayoutBoxConfig>
  ): Promise<Array<LayoutBoxConfig>> {
    const response = apiDeserializer(await service.update(csId, apiSerializer(id, value)))
    return response.value
  }

  async function remove(id: string) {
    const csId = await getLayoutServiceId(id)
    await service.remove(csId)
  }

  return {
    loading,
    createId,
    create,
    load,
    update,
    remove
  }
}
