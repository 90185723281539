import { defineStore } from 'pinia'
import { TagScopes, type TagEachResource, type TagResourceResponse } from '@/lib/api'
import { useServices } from '@/lib/services'
import { computed, reactive, ref } from 'vue'
import { groupBy } from 'lodash'

type CameraStateManagement = {
  tags: TagEachResource[]
  resourceWorkspace: TagResourceResponse | null
  tempWorkspaceSelectedTags: TagEachResource[]
}

interface GroupedTags {
  [key: string]: Array<TagEachResource>
}

const emptyResourceWorkspace: TagResourceResponse = {
  id: '',
  remoteId: '',
  type: '',
  tags: [],
  createdAt: new Date()
}

export const useTagStore = defineStore('TagStore', () => {
  const tags: Array<TagEachResource> = reactive([])
  const resourceWorkspace = reactive<TagResourceResponse>(emptyResourceWorkspace)
  const tempWorkspaceSelectedTags: Array<TagEachResource> = reactive([])

  const groupedTags = computed((): GroupedTags => {
    const grouped = groupBy(tags, 'scope')

    // Get the custom group (if it exists)
    const customGroup = grouped[TagScopes.custom] || []

    // Merge the custom group into all other groups
    Object.keys(grouped).forEach((scope) => {
      if (scope !== TagScopes.custom) {
        grouped[scope] = [...grouped[scope], ...customGroup]
      }
    })

    return grouped
  })

  const workspaceSelectedTags = computed((): TagEachResource[] => {
    const selectedTags: TagEachResource[] = [...tempWorkspaceSelectedTags]
    return selectedTags
  })

  const filterdWorkspaceTags = computed((): TagEachResource[] => {
    if (groupedTags.value.workspace) {
      return groupedTags.value.workspace.filter((tag) => {
        return !workspaceSelectedTags.value.some((selected) => tag.id === selected.id)
      })
    } else {
      return []
    }
  })
  const selectedLength = computed(() => workspaceSelectedTags.value.length)

  const resourceLength = computed(() => resourceWorkspace.tags.length)

  const isLengthChanged = computed(() => resourceLength.value !== selectedLength.value)

  const isWorkspaceTagsChanged = computed((): boolean => {
    return (
      isLengthChanged.value ||
      !workspaceSelectedTags.value.every((selected) =>
        resourceWorkspace.tags.some((tag) => tag.id === selected.id)
      )
    )
  })

  async function loadTags(force: boolean = false) {
    if (tags.length === 0 || force) {
      const fetched = await useServices().tagManager.tag.list()
      Object.assign(tags, fetched)
    }
  }

  async function fetchWorkspaceResource(id: string, force: boolean = false) {
    if (!resourceWorkspace.id || force) {
      await useServices()
        .tagManager.resource.findResource({
          remoteId: id,
          type: 'w'
        })
        .then((resource) => {
          const fetchResource = resource[0]
          if (fetchResource.id) {
            Object.assign(resourceWorkspace, fetchResource)
            cloneTagsFromResource(fetchResource.tags)
          }
        })
    }
  }

  function cloneTagsFromResource(tagResource: TagEachResource[]) {
    const filtered = tagResource.filter(
      (tag) => !tempWorkspaceSelectedTags.some((tempTag) => tempTag.id === tag.id)
    )
    selectTag(filtered)
  }

  async function addTag(scope: TagScopes, value: string): Promise<TagEachResource> {
    const newTag = await useServices().tagManager.tag.create({
      scope,
      value
    })
    tags.push(newTag)
    return newTag
  }

  function selectTag(tags: TagEachResource[]) {
    tempWorkspaceSelectedTags.length = tags.length
    Object.assign(tempWorkspaceSelectedTags, tags)
  }

  async function updateTags() {
    if (resourceWorkspace) {
      const addedTag = workspaceSelectedTags.value.filter(
        (itemB) => !resourceWorkspace.tags.some((itemA) => itemA.id === itemB.id)
      )
      const deletedTag =
        resourceWorkspace?.tags.filter(
          (itemA) => !workspaceSelectedTags.value.some((itemB) => itemB.id === itemA.id)
        ) || []

      if (addedTag.length > 0) {
        await assignTag(addedTag)
      }
      if (deletedTag.length > 0) {
        await dropTag(deletedTag)
      }
    } else {
      throw { getResource: true }
    }
  }

  async function assignTag(tags: TagEachResource[]) {
    if (tags && tags.length > 0 && resourceWorkspace?.id) {
      const reqs = new Array<Promise<any>>()
      for (const tag of tags) {
        reqs.push(
          useServices().tagManager.resource.assignResource({
            tagId: tag.id,
            resourceId: resourceWorkspace.id
          })
        )
      }
      await Promise.all(reqs)
      resourceWorkspace.tags.push(...tags)
    }
  }

  async function dropTag(tags: TagEachResource[]) {
    if (tags && tags.length > 0 && resourceWorkspace?.id) {
      const reqs = new Array<Promise<any>>()
      for (const tag of tags) {
        reqs.push(
          useServices().tagManager.resource.dropResource({
            tagId: tag.id,
            resourceId: resourceWorkspace.id
          })
        )
      }
      await Promise.all(reqs)
      resourceWorkspace.tags = resourceWorkspace.tags.filter(
        (tag) => !tags.some((removed) => removed.id === tag.id)
      )
    }
  }

  function reset() {
    tags.length = 0
    resourceWorkspace['id'] = ''
    resourceWorkspace['workspaceId'] = ''
    resourceWorkspace['remoteId'] = ''
    resourceWorkspace['type'] = ''
    resourceWorkspace['tags'] = []
    resourceWorkspace['createdAt'] = new Date()
    tempWorkspaceSelectedTags.length = 0
  }

  return {
    tags,
    resourceWorkspace,
    tempWorkspaceSelectedTags,
    groupedTags,
    workspaceSelectedTags,
    filterdWorkspaceTags,
    isWorkspaceTagsChanged,
    loadTags,
    fetchWorkspaceResource,
    cloneTagsFromResource,
    addTag,
    selectTag,
    updateTags,
    assignTag,
    dropTag,
    reset
  }
})
