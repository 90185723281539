import type { WorkspaceStatus } from '@/lib/api'
import { useServices } from '@/lib/services'
import { defineStore } from 'pinia'
import { reactive } from 'vue'
import { WorkspaceStatusTranslate } from '@/modules/Workspace/store/workspaceStores.interface'
import { useWorkspaceStore } from '@/modules/Workspace/store/useWorkspaceStore'
import { useEventBus } from '@/utils/event-bus/EventBus'
import { ConvertError } from '@/utils/helpers'

export const useWorkspaceStatusStore = defineStore('WorkspaceStatus', () => {
  const _service = useServices().workspaceManager.workspace

  const statusList: Array<WorkspaceStatus> = reactive([])

  async function fetchStatuses(force: boolean = false): Promise<void> {
    if (
      statusList.length === 0 ||
      useWorkspaceStore().workspaces.length !== statusList.length ||
      force
    ) {
      try {
        const statuses = await _service.findStatus()
        Object.assign(statusList, statuses)
      } catch (e) {
        useEventBus().emit('toast.add', {
          severity: 'error',
          summary: 'Workspaces statuses',
          detail: ConvertError(e),
          life: 3000
        })
      }
    }
  }

  function getStatus(id: string): WorkspaceStatus {
    return (
      statusList.find((s) => s.id === id) || {
        id,
        activePayment: false,
        locked: false,
        lockForUser: false
      }
    )
  }

  function translateStatus(id: string): WorkspaceStatusTranslate {
    const status = getStatus(id)
    if (status.activePayment && !status.locked) {
      return WorkspaceStatusTranslate.healthy
    } else if (status.activePayment && status.locked && !status.lockForUser) {
      return WorkspaceStatusTranslate.freeze
    } else if (!status.activePayment && status.locked && status.lockForUser) {
      return WorkspaceStatusTranslate.userLocked
    } else if (!status.activePayment && status.locked && !status.lockForUser) {
      return WorkspaceStatusTranslate.expiredOwner
    } else {
      return WorkspaceStatusTranslate.expiredOwner
    }
  }

  function reset(): void {
    statusList.length = 0
  }

  return {
    statusList,
    fetchStatuses,
    translateStatus,
    getStatus,
    reset
  }
})
