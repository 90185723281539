import { computed, markRaw, reactive, ref } from 'vue'
import type { ComputedRef, Ref } from 'vue'
import type { BridgeActiveData, BridgeData } from '@/lib/api'
import type { CameraTemp } from '@/modules/Camera/interface'
import type { DiscoveredCamera } from '@/lib/api'
import { useServices } from '@/lib/services'
import { Bridge } from '@/modules/Bridge/Bridge'
import { ActiveBridgeStateType, BridgeDrawerMode } from '@/modules/Bridge/types'
import { useOnboardingStore } from '@/modules/Onboarding/store/useOnboardingStore'
import { useCameraStore } from '@/modules/Camera/store/cameraStore'
import { useCreateCameraTempData } from '@/modules/Camera/helpers/useCameraHelper'
import { defineStore, storeToRefs } from 'pinia'
import { useTabStore } from '@/stores/tab/useTabStore'
import { useImpexStore } from '@/stores/import-export/useImpexStore'

export const useBridgeStore = defineStore('BridgeStore', () => {
  const _services = useServices()
  const _currentBridgeIndex: Ref<number> = ref(-1)
  const _onboardingStore = useOnboardingStore()
  const _tabStore = useTabStore()
  const _cameraStore = useCameraStore()
  const _impexStore = useImpexStore()
  const _fetchingBridges: Ref<Promise<any> | undefined> = ref()
  const discovery: Ref<boolean> = ref(false)
  const bridges: Array<Bridge> = reactive([])
  const bridgesIdList: string[] = reactive([])
  const drawerData = reactive({
    show: false,
    mode: BridgeDrawerMode.create,
    readonly: false,
    loading: false,
    activeBridgeState: ActiveBridgeStateType.activate
  })

  const hasBridge = computed(() => bridgesIdList.length > 0)

  const currentBridgeId = computed((): string => {
    if (currentBridge.value) {
      return currentBridge.value.id
    } else {
      return ''
    }
  })

  const currentBridgeIndex = computed({
    get(): number {
      return _currentBridgeIndex.value
    },
    set(index) {
      if (index !== undefined) {
        _currentBridgeIndex.value = index
      }
    }
  })

  const currentBridge: ComputedRef<Bridge | undefined> = computed(() => {
    return bridges[currentBridgeIndex.value]
  })

  const loading: ComputedRef<boolean> = computed(() => _fetchingBridges.value !== undefined)

  function _pushBridgeTolist(bridge: Bridge) {
    if (!bridges.some((b) => b.id === bridge.id)) bridges.push(markRaw(bridge))
    if (!bridgesIdList.some((id) => id === bridge.id)) bridgesIdList.push(bridge.id)
  }

  async function createBridge(
    bridgeData: BridgeData | BridgeActiveData | string,
    needToSetup: boolean = true
  ): Promise<Bridge> {
    const bridge: Bridge | undefined = await Bridge.init(bridgeData as string)
    if (bridge) {
      _pushBridgeTolist(bridge)
      if (needToSetup) {
        finishSetupHandler(false, bridge.id)
      }
    }
    return bridge
  }

  async function getBridge(id: string): Promise<Bridge> {
    const bridgeIndex = bridges.findIndex((b) => b.id === id)
    if (bridgeIndex >= 0) {
      return reactive(markRaw(bridges[bridgeIndex]))
    } else {
      return await fetchBridge(id)
    }
  }
  function getFetchedBridge(id: string): Bridge | undefined {
    const bridgeIndex = bridges.findIndex((b) => b.id === id)
    return bridges[bridgeIndex] ? reactive(markRaw(bridges[bridgeIndex])) : undefined
  }

  function setDiscover(discover: boolean) {
    discovery.value = discover
  }

  async function setCurrentBridge(id?: string, resetFinishSetup: boolean = true) {
    try {
      if (id !== undefined) {
        const bridge = await getBridge(id)
        if (bridge !== undefined) {
          const index = bridges.findIndex((b) => b.id === id)
          currentBridgeIndex.value = index
          if (resetFinishSetup) {
            finishSetupHandler(false, id)
          }
        }
      } else {
        finishSetupHandler(true)
      }
    } catch (e) {
      finishSetupHandler(true)
    }
  }

  async function fetchBridges(force: boolean = false) {
    if ((bridges.length === 0 || force) && _fetchingBridges.value === undefined) {
      let bridgeList: Array<BridgeData> = []
      _fetchingBridges.value = _services.bridge.list().then(async (bridgesResponse) => {
        bridgeList = bridgesResponse

        const promises = bridgeList.map(async (data) => {
          createBridge(data, !_onboardingStore.isBridgeSetupDone)
        })
        _fetchingBridges.value = Promise.all(promises).then(() => {
          _fetchingBridges.value = undefined
        })
        if (bridgeList.length > 0 && !_onboardingStore.isBridgeSetupDone) {
          const id = _onboardingStore.selectedBridge
          if (id !== '') {
            setCurrentBridge(id)
          } else {
            currentBridgeIndex.value = 0
          }
        } else if (bridgeList.length === 0) {
          finishSetupHandler(true)
        }
      })
    }
  }

  async function fetchBridge(id: string, force: boolean = false): Promise<Bridge> {
    const bridgeObject = getFetchedBridge(id)
    if (!bridgeObject || force) {
      const bridge = await createBridge(id)
      return bridge
    } else return bridgeObject
  }

  async function remove(id: string, force: boolean = false): Promise<void> {
    const bridge = getFetchedBridge(id)
    if (bridge) {
      const id = bridge.id
      const onboardingData = _onboardingStore.data
      if (force) {
        await bridge.forceHardReset()
      } else {
        await bridge.hardReset()
      }
      _cameraStore.removeBridges(id)
      _removeFromList(id)

      onboardingData.camera.cameraList = onboardingData.camera.cameraList.filter(
        (camera) => camera.cameraData.bridgeId !== id
      )
      onboardingData.camera.selections = onboardingData.camera.selections.filter(
        (camera) => camera.cameraData.bridgeId !== id
      )

      if (bridges.length === 0) finishSetupHandler(true)

      const onboardingTab = _tabStore.tabs.find((item) => item.route === 'onboarding')
      const cameraConnectTab = _tabStore.tabs.find((item) => item.route === 'cameraConnect')
      if (onboardingTab) _impexStore.updateTabState('onboarding')
      if (cameraConnectTab) _impexStore.updateTabState('cameraConnect')
    }
  }

  function finishSetupHandler(state: boolean = false, id?: string) {
    if (!state && id) {
      _onboardingStore.setBridgeSetupState(false, id)
    } else if (state) {
      currentBridgeIndex.value = -1
      _onboardingStore.setBridgeSetupState(true)
    }
  }

  async function forceRemove(id: string) {
    const bridge = getFetchedBridge(id)
    if (bridge) {
      if (currentBridgeId.value === id) {
        finishSetupHandler(true)
      }
      const { data } = storeToRefs(_onboardingStore)
      await bridge.forceHardReset()
      _cameraStore.removeBridges(id)
      _removeFromList(id)

      data.value.camera.cameraList = data.value.camera.cameraList.filter(
        (camera) => camera.cameraData.bridgeId !== id
      )
      data.value.camera.selections = data.value.camera.selections.filter(
        (camera) => camera.cameraData.bridgeId !== id
      )
      const onboardingTab = _tabStore.tabs.find((item) => item.route === 'onboarding')
      const cameraConnectTab = _tabStore.tabs.find((item) => item.route === 'cameraConnect')
      if (onboardingTab) _impexStore.updateTabState('onboarding')
      if (cameraConnectTab) _impexStore.updateTabState('cameraConnect')
    }
  }

  function _removeFromList(id: string) {
    const bridge = getFetchedBridge(id)
    if (bridge) {
      bridge.statusManager.unregisterForUpdater()
      const bridgeIndex = bridges.findIndex((b) => b.id === bridge.id)
      if (bridgeIndex >= 0) bridges.splice(bridgeIndex, 1)
      const bridgeIdIndex = bridgesIdList.findIndex((id) => id === bridge.id)
      if (bridgeIdIndex >= 0) bridgesIdList.splice(bridgeIndex, 1)
    }
  }

  function changeActivateState(state: ActiveBridgeStateType) {
    drawerData.activeBridgeState = state
  }

  function reset() {
    bridges.length = 0
    bridgesIdList.length = 0
    Object.assign(drawerData, {
      show: false,
      mode: BridgeDrawerMode.create,
      readonly: false,
      loading: false,
      activeBridgeState: ActiveBridgeStateType.activate
    })
  }

  async function autoDiscover() {
    try {
      if (currentBridge.value) {
        setDiscover(true)
        closeDrawer()
        const cameras = await currentBridge.value.fastDiscovery()
        if (cameras.length > 0) await createCameraTemp(cameras)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setDiscover(false)
    }
  }

  async function createCameraTemp(cameras: DiscoveredCamera[]) {
    const groups = await _cameraStore.getCameraGroups()
    let defaultCameraGroup = ''
    if (groups.length > 0) {
      defaultCameraGroup = groups[0].id
    }
    cameras.forEach((camera) => {
      const hasCameraTemp = _onboardingStore.hasCameraTemp(camera.ip, currentBridgeId.value)
      if (!hasCameraTemp) {
        const newCameraTempData: CameraTemp = useCreateCameraTempData({
          name: camera.model,
          groupId: defaultCameraGroup,
          url: camera.ip,
          manufacturerCode: camera.manufacturer,
          bridgeId: currentBridgeId.value
        })
        _onboardingStore.addCameraTemp(newCameraTempData)
      }
    })
  }

  async function openDrawer(
    id: string | undefined,
    mode: BridgeDrawerMode = BridgeDrawerMode.profile,
    loading: boolean = false,
    readonly: boolean = false
  ) {
    if (mode === BridgeDrawerMode.profile && id) {
      await setCurrentBridge(id, false)
      drawerData.mode = mode
      drawerData.readonly = readonly
      if (loading) setDrawerLoading(true)
    } else {
      drawerData.mode = BridgeDrawerMode.create
      drawerData.readonly = false
    }
    drawerData.show = true
  }

  function closeDrawer() {
    drawerData.readonly = false
    drawerData.show = false
  }

  function setDrawerLoading(loading: boolean) {
    if (drawerData.show) {
      drawerData.loading = loading
    }
  }

  return {
    bridges,
    bridgesIdList,
    hasBridge,
    currentBridgeId,
    currentBridgeIndex,
    currentBridge,
    drawerData,
    discovery,
    loading,
    setDiscover,
    autoDiscover,
    getFetchedBridge,
    getBridge,
    setCurrentBridge,
    finishSetupHandler,
    createBridge,
    fetchBridges,
    fetchBridge,
    remove,
    forceRemove,
    removeFromList: _removeFromList,
    changeActivateState,
    reset,
    createCameraTemp,
    openDrawer,
    closeDrawer,
    setDrawerLoading
  }
})
