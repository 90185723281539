import { NotificationNames } from '@/lib/api'

export interface Notification {
  id: string
  userId: string
  workspaceId: string
  type: string
  priority: string
  title: string
  name: string
  content: string
  code: string
  notifiableId: string
  notifiableType: string
  readAt: string
  createdAt: string
  clearedAt: string
  action: any
  resourceId: string
  shortTitle: string
  metadata: Metadata
  isSlider?: boolean // Frontend property
}
export interface Metadata {
  job: string
  time: number
  typeNum: number
  index: string
  analyticName: string
}

export enum NotificationColor {
  red = 're',
  babyBlue = 'bb',
  turquoise = 'tu',
  green = 'gr',
  yellow = 'ye'
}

export enum NotifierResourceTypes {
  Camera = 'c',
  CameraGroup = 'cg',
  Workspace = 'w',
  Map = 'm',
  Bridge = 'br',
  BridgeCamera = 'br-c',
  User = 'u',
  Bookmark = 'b',
  Team = 't'
}
export interface NotificationFindDto {
  read?: boolean
  clear?: boolean
  name?: NotificationNames[]
  resourceId?: string
  resourceType?: NotifierResourceTypes
  before?: string
  after?: string
  noteQuery?: string
  ids?: Array<string>
}
