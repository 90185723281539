import { CameraService, ResourceTypes } from '@/lib/api'
import type { Camera as CameraObject, CameraReturnType } from '@/lib/api'
import { useServices } from '@/lib/services'
import type { CameraTemp } from '@/modules/Camera/interface'
import { CameraConnectionInformation } from '@/modules/Camera/libs/connection-information/CameraConnectionInformation'
import { CameraBaseInfo } from '@/modules/Camera/libs/camera-base-info/CameraBaseInfo'
import { CameraRecord } from '@/modules/Camera/libs/camera-record/CameraRecord'
import { CameraAvatar } from '@/modules/Camera/libs/avatar/CameraAvatar'
import { CameraStatus } from '@/modules/Camera/libs/status/CameraStatus'
import { CameraThumbnail } from '@/modules/Camera/libs/thumbnail/CameraThumbnail'
import { TagManager } from '@/modules/tag/lib/TagManager'
import { CameraHardware } from '@/modules/Camera/libs/hardware-information/CameraHardware'
import { CameraStream } from '@/modules/Camera/libs/stream-information/CameraStream'
import { CameraStatusType } from '@/modules/Camera/types'
import { CameraGroup } from '@/modules/Camera/libs/camera-group/CameraGroup'
import { CameraBridgePivot } from '@/modules/Camera/libs/bridge-pivot/CameraBridgePivot'
import { CameraUsers } from '@/modules/Camera/libs/users/CameraUsers'
import { markRaw, ref, type Ref } from 'vue'
import { generateUniqueId } from '@/utils/helpers/general'
import { getPlayerRepository } from '@/player/lib/player/player-repository'

export class CameraCore {
  public cameraObject: CameraObject
  protected readonly service: CameraService = useServices().camera
  protected fetchingPromise: Promise<any> | undefined = undefined
  public readonly id: string
  public readonly base: CameraBaseInfo
  public readonly connection: CameraConnectionInformation
  public readonly users: CameraUsers
  public readonly record: CameraRecord
  public readonly avatar: CameraAvatar
  public readonly status: CameraStatus
  public readonly thumbnail: CameraThumbnail
  public readonly hardware: CameraHardware
  public readonly streams: CameraStream
  public readonly group: CameraGroup
  public readonly bridge: CameraBridgePivot
  public locked: Boolean = false
  public readonly waiting: Ref<boolean> = ref(false)
  public tagManager: TagManager

  // public readonly archives: CameraArchive
  // public readonly featureMatrix: CameraFeatureMatrixPivot
  // public readonly heatmap: CameraHeatmapPivot
  // public readonly tracker: CameraTrackerPivot
  // public readonly actionLogs: CameraActionLogsPivot

  protected constructor(cameraObject: CameraObject, waiting: boolean = false) {
    this.waiting.value = waiting
    this.cameraObject = cameraObject
    this.id = cameraObject.id
    this.base = new CameraBaseInfo(this, cameraObject)
    this.users = new CameraUsers(cameraObject.id)
    this.connection = new CameraConnectionInformation(this, cameraObject)
    this.hardware = new CameraHardware(this, cameraObject)
    this.streams = new CameraStream(this, cameraObject)
    this.record = new CameraRecord(this, cameraObject)
    this.avatar = new CameraAvatar(this)
    this.status = new CameraStatus(this)
    this.thumbnail = new CameraThumbnail(this)
    this.group = new CameraGroup(this, cameraObject)
    this.bridge = new CameraBridgePivot(this, cameraObject)
    this.tagManager = new TagManager(cameraObject.id, ResourceTypes.Camera)
  }

  public static async init(camera: string | CameraTemp | CameraObject): Promise<CameraCore> {
    if (typeof camera === 'string') {
      return await this.initById(camera)
    } else if (typeof camera === 'object' && 'id' in camera) {
      return await this.initByCamera(camera)
    } else {
      return await this.initTemp(camera)
    }
  }

  private static async initById(id: string): Promise<CameraCore> {
    const camera = await this.loadCameraDetail(id)
    return this.initByCamera(camera)
  }

  private static async initTemp(cameraTemp: CameraTemp): Promise<CameraCore> {
    const cameraData = await useServices().camera.create(cameraTemp.cameraData)
    const camera = await this.initByCamera(cameraData, true)
    return camera
  }

  private static async initByCamera(
    camera: CameraObject,
    wait: boolean = false
  ): Promise<CameraCore> {
    return new CameraCore(camera, wait)
  }

  async getCameraData(): Promise<void> {
    if (this.fetchingPromise === undefined) {
      this.fetchingPromise = Promise.all([
        this.status.update(true),
        this.users.load(),
        this.base.locationManager.loadData(),
        this.avatar.load(),
        this.tagManager.load(),
        this.base.metadataManager.load()
      ]).then(() => {
        this.fetchingPromise = undefined
        this.waiting.value = false
      })
      return this.fetchingPromise
    }
  }
  async getCameraBasicData(): Promise<void> {
    await Promise.all([this.status.update(true)])
  }

  private static async loadCameraDetail(id): Promise<CameraObject> {
    return await useServices().camera.find(id)
  }

  public async delete(): Promise<string> {
    if (!this.locked) {
      await this.service.remove(this.id)
      return this.id
    }
    return ''
  }

  public clone(): CameraTemp {
    return JSON.parse(
      JSON.stringify({
        cameraData: {
          name: this.base.data.name,
          groupId: this.group.groupId,
          config: this.connection.data,
          bridgeId: this.bridge.bridgeId,
          timezone: this.base.data.timezone || undefined,
          userData: generateUniqueId(),
          createdAt: this.base.data.createdAt,
          updatedAt: this.base.data.updatedAt
        },
        tags: this.tagManager.tags,
        avatar: this.avatar.avatar,
        status: CameraStatusType.offline,
        location: this.base.locationManager.location
      })
    )
  }

  async updateCameraObject(camera: Partial<CameraObject>): Promise<void> {
    Object.assign(this.cameraObject, camera)
    getPlayerRepository().updateInformation(this.cameraObject)
  }

  public updateCameraData(camera: CameraObject) {
    if (!this.locked) {
      this.base.initialCameraData(camera)
      this.connection.initialCameraData(camera)
      this.hardware.initialCameraData(camera)
      this.streams.initialCameraData(camera)
      this.record.updateDependencies(camera as CameraReturnType)
    }
  }

  public block(state: boolean) {
    this.locked = state
  }
}
