export enum Scopes {
  workspace = 'workspace',
  camera = 'camera',
  bridge = 'bridge',
  map = 'map',
  metadata = 'metadata',
  user = 'user',
  team = 'team',
  bookmark = 'bookmark',
  event = 'event',
  custom = 'custom'
}
