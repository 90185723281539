import { Actions } from '@/modules/Permissions'
import type { RouteLocationRaw, RouteRecordRaw } from 'vue-router'
import { createRouter as createVueRouter, createWebHashHistory } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'authentication',
    component: () => import('@/views/authentication/Authentication.vue'),
    meta: {
      dontMakeTab: true
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/authentication/ResetPassword.vue'),
    meta: {
      dontMakeTab: true
    }
  },
  {
    path: '/recovery-password',
    name: 'recovery-password',
    component: () => import('@/views/authentication/RecoveryMultiFactor.vue'),
    meta: {
      dontMakeTab: true
    }
  },
  {
    path: '/404',
    name: '404-static',
    component: () => import('@/views/errors/404.vue'),
    meta: {
      dontMakeTab: true
    }
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('@/views/errors/404.vue'),
    meta: {
      dontMakeTab: true
    }
  },
  {
    path: '/login-away-error',
    name: 'loginAwayError',
    component: () => import('@/views/errors/LoginAwayError.vue'),
    meta: {
      dontMakeTab: true
    }
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: () => import('@/views/errors/forbidden.vue'),
    meta: {
      dontMakeTab: true
    }
  },
  {
    path: '/interruption',
    name: 'interruption',
    component: () => import('@/views/errors/interruption.vue'),
    meta: {
      dontMakeTab: true
    }
  },
  {
    path: '/capacity',
    name: 'capacity',
    component: () => import('@/views/authentication/Capacity.vue'),
    meta: {
      dontMakeTab: true
    }
  },
  {
    path: '/app',
    name: 'application',
    component: () => import('@/layouts/index.vue'),
    meta: {
      requiredAuthentication: true
      // dontMakeTab: true
    },
    children: [
      {
        path: 'profile',
        name: 'profile',
        redirect: { name: 'profileSettings' },
        component: () => import('@/views/profile/profileSettings.vue'),
        meta: {
          requiredAuthentication: true
        },
        children: [
          {
            path: 'settings',
            name: 'profileSettings',
            component: () => import('@/components/profile/profile.vue'),
            meta: {
              requiredAuthentication: true
            }
          },
          {
            path: 'security',
            name: 'securitySettings',
            component: () => import('@/components/security/security.vue'),
            meta: {
              requiredAuthentication: true
            }
          },
          {
            path: 'notifications',
            name: 'notificationsSettings',
            component: () => import('@/modules/Notifications/views/index.vue'),
            meta: {
              requiredAuthentication: true
            }
          }
        ]
      },
      {
        path: 'workspace',
        name: 'workspace',
        redirect: { name: 'workspaceSettings' },
        component: () => import('@/modules/Workspace/layouts/workspaceSettings.vue'),
        meta: {
          requiredWorkspace: true,
          requiredAuthentication: true
        },
        children: [
          {
            path: 'settings',
            name: 'workspaceSettings',
            component: () => import('@/modules/Workspace/views/workspaceOverview.vue'),
            meta: {
              requiredWorkspace: true,
              requiredAuthentication: true
            }
          },
          {
            path: 'billing',
            name: 'billingSettings',
            component: () => import('@/components/billing/BillingContainer.vue'),
            meta: {
              requiredWorkspace: true,
              requiredAuthentication: true,
              requiredAuthorities: [Actions.workspaceViewPayment]
            }
          }
        ]
      },
      {
        path: 'permission-settings',
        name: 'permissionSettings',
        meta: {
          requiredWorkspace: true,
          requiredAuthentication: true,
          requiredAuthorities: [Actions.workspaceViewUsersSummery, Actions.workspaceViewPermissions]
        },
        component: () => import('@/modules/Permissions/views/index.vue')
      },
      {
        path: 'onboarding',
        name: 'onboarding',
        meta: {
          requiredWorkspace: true,
          requiredAuthentication: true,
          requiredAuthorities: [Actions.workspaceViewPayment]
        },
        component: () => import('@/modules/Onboarding/views/OnboardingView.vue')
      },
      {
        name: 'help',
        path: 'help',
        children: [
          {
            name: 'tutorial',
            path: 'tutorial',
            component: () => import('@/modules/Onboarding/views/Tutorial.vue')
          },
          {
            path: 'shortcuts',
            name: 'shortcuts',
            component: () => import('@/modules/Shortcuts/views/index.vue')
          }
        ]
      },
      {
        path: 'camera-connect',
        name: 'cameraConnect',
        meta: {
          requiredWorkspace: true,
          requiredAuthentication: true,
          requiredAuthorities: [Actions.cameraCreate]
        },
        component: () => import('@/modules/Onboarding/views/OnboardingView.vue')
      },
      {
        path: 'stream',
        name: 'monitoring',
        meta: {
          requiredWorkspace: true,
          requiredAuthentication: true
        },
        component: () => import('@/modules/Monitoring/components/BaseNewMonitoring.vue')
      },
      {
        path: 'streaming/:tabId',
        name: 'streaming',
        meta: {
          requiredWorkspace: true,
          requiredAuthentication: true,
          style: {
            noPadding: true
          }
        },
        component: () => import('@/modules/Monitoring/components/BaseMonitoring.vue')
      },
      {
        path: 'streaming-listview',
        name: 'streamingListView',
        meta: {
          requiredWorkspace: true,
          requiredAuthentication: true,
          style: {
            noPadding: true
          }
        },
        component: () => import('@/modules/Monitoring/components/BaseMonitoringListView.vue')
      },
      {
        path: 'streaming-playback/:tabId',
        name: 'streamingPlayback',
        meta: {
          requiredWorkspace: true,
          requiredAuthentication: true,
          style: {
            noPadding: true
          }
        },
        component: () => import('@/modules/Monitoring/components/BaseSyncPlaybackMonitoring.vue')
      },
      {
        path: 'streaming-heatmap/:tabId',
        name: 'streamingHeatmap',
        meta: {
          requiredWorkspace: true,
          requiredAuthentication: true,
          requiredAuthorities: [Actions.aiHeatmapFind],
          style: {
            noPadding: true
          }
        },
        component: () => import('@/modules/Monitoring/components/BaseMonitoringHeatmap.vue')
      },
      {
        path: 'ai-search/:id/:chatId',
        name: 'aiSearch',
        meta: {
          requiredWorkspace: true,
          requiredAuthentication: true,
          requiredAuthorities: [Actions.aiTrackFind, Actions.aiTrackStatisticsFind]
        },
        component: () => import('@/views/ai-search/index.vue')
      },
      {
        path: 'clips',
        name: 'clips',
        meta: {
          requiredWorkspace: true,
          requiredAuthentication: true
          // actions: [{ action: Actions.cameraViewExport }, { action: Actions.cameraViewSnapshot }] // this is not relevant and actually wrong since the backend filter the list of cameras
        },
        component: () => import('@/modules/Camera/views/Clips/index.vue')
      },
      {
        path: 'camera',
        name: 'camera',
        component: () => import('@/modules/Camera/views/CameraDetail/index.vue'),
        redirect: { name: 'cameraDetail' },
        meta: {
          requiredWorkspace: true,
          requiredAuthentication: true,
          style: {
            noPadding: true
          }
        },
        children: [
          {
            path: 'detail/:id',
            name: 'cameraDetail',
            meta: {
              requiredWorkspace: true,
              requiredAuthentication: true,
              style: {
                noPadding: true
              }
            },
            component: () => import('@/modules/Camera/views/CameraDetail/CameraStream.vue')
          }
        ]
      },
      {
        path: 'camera-permission/:id',
        name: 'cameraPermission',
        meta: {
          requiredWorkspace: true,
          requiredAuthentication: true,
          style: {
            noPadding: true
          }
        },
        component: () => import('@/modules/Camera/views/CameraDetail/index.vue')
      }
    ]
  },
  {
    path: '/select-workspace',
    name: 'SelectWorkspace',
    meta: {
      requiredAuthentication: true,
      dontMakeTab: true
    },
    component: () => import('@/modules/Workspace/views/workspaceSelect.vue')
  }
]

export function useRouterConfig() {
  function getAuthenticationRoute(): RouteLocationRaw {
    return { name: 'authentication' }
  }

  function getForbiddenRoute(): RouteLocationRaw {
    return { name: 'forbidden' }
  }

  function getSelectWorkspaceRoute(): RouteLocationRaw {
    return { name: 'SelectWorkspace' }
  }

  function createRouter() {
    return createVueRouter({
      history: createWebHashHistory(import.meta.env.BASE_URL),
      routes,
      scrollBehavior(to) {
        if (to.hash) {
          return {
            el: to.hash,
            behavior: 'smooth'
          }
        } else return { top: 0 }
      }
    })
  }

  return {
    getAuthenticationRoute,
    getForbiddenRoute,
    getSelectWorkspaceRoute,
    createRouter
  }
}
