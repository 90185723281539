import { createRouter, createWebHashHistory, type RouteLocationNormalized } from 'vue-router'
import routes from './routes'
import { useApplicationStore } from '@/stores/useApplicationStore'
import { useAuthenticationStore } from '@/stores/authentication/useAuthenticationStore'
import { useThemeStore } from '@/stores/Theme/useThemeStore'
import { useWorkspaceStore } from '@/modules/Workspace/store/useWorkspaceStore'
import { usePermissionManager } from '@/modules/Permissions'
import middlewarePipeline from '../middleware/middlewarePipeline'
import { useMonitoringTabsWrapper } from '@/modules/Monitoring/helpers/monitoringTabs'

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [...routes],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    } else return { top: 0 }
  }
})

const authenticationRoutes = ['authentication', 'reset-password', 'recovery-password']
const openRoutes = ['404', 'forbidden', 'interruption', 'capacity']
const unGuardedRoutes = [...authenticationRoutes, ...openRoutes]

const updateDocumentTitle = () => {
  const workspaceStore = useWorkspaceStore()
  let title = 'ArcadianAI Secure Stream'
  if (workspaceStore.currentWorkspace?.name) {
    title = `${workspaceStore.currentWorkspace?.name}, ArcadianAI`
  }
  document.title = title
}

router.afterEach((to, from) => {
  updateDocumentTitle()
  if (
    !unGuardedRoutes.includes(String(to.name)) &&
    (from.path === '/' || from.name === 'SelectWorkspace')
  ) {
    useApplicationStore().stopLoading(String(to.name))
  }
})

router.beforeEach(async (to, from, next) => {
  try {
    if (
      !unGuardedRoutes.includes(String(to.name)) &&
      (from.path === '/' || from.name === 'SelectWorkspace')
    ) {
      useApplicationStore().startLoading(String(to.name))
    }
    handlePlatformQuery(to, next)

    if (isMobilePlatform() && isGuardedRoute(to)) {
      return useAuthenticationStore().logout()
    }

    if (isSandboxRoute(to)) {
      return next()
    }

    if (shouldLoadApplicationData(to)) {
      await loadApplicationData(to, from, next)
    } else if (isSelectWorkspaceRoute(to)) {
      await useAuthenticationStore().initializeTokens()
    }

    if (isAuthenticatedRoute(to)) {
      return handleAuthenticatedRoute(to, next)
    }

    if (isNewMonitoringRoute(to)) {
      return next(useMonitoringTabsWrapper().createMonitoringTab())
    }

    if (hasMiddleware(to) && !isGuardedRoute(to)) {
      return applyMiddleware(to, from, next)
    }
    next()
  } catch (error) {
    console.error('Router beforeEach error:', error)
    next({ name: 'interruption' })
  }
})

const handlePlatformQuery = (to, next) => {
  if (to.query.platform === 'mobile') {
    localStorage.setItem('platform', 'mobile')
  }

  if (to.query.theme && ['dark', 'light'].includes(to.query.theme)) {
    useThemeStore().changeTheme(to.query.theme)
  }

  if (
    isMobilePlatform() &&
    unGuardedRoutes.includes(String(to.name)) &&
    to.query.platform !== 'mobile'
  ) {
    const query = { ...to.query, platform: 'mobile' }
    return next({ ...to, query })
  }
}

const isMobilePlatform = () => localStorage.getItem('platform') === 'mobile'

const isGuardedRoute = (to) => !unGuardedRoutes.includes(String(to.name))

const isSandboxRoute = (to) => String(to.name).includes('sandbox')

const isNewMonitoringRoute = (to: RouteLocationNormalized) => to.name === 'monitoring'

const shouldLoadApplicationData = (to) => {
  return isGuardedRoute(to) && to.name !== 'SelectWorkspace'
}

const isSelectWorkspaceRoute = (to) => to.name === 'SelectWorkspace'

const loadApplicationData = async (to, from, next) => {
  const permissionManager = usePermissionManager()

  if (permissionManager.userPermissions.permissions.length === 0) {
    await useApplicationStore().loadApplicationData()
    await usePermissionManager().permissionsMiddleware(to, from, next)
  } else {
    await usePermissionManager().permissionsMiddleware(to, from, next)
  }
}

const isAuthenticatedRoute = (to) => authenticationRoutes.includes(String(to.name))

const handleAuthenticatedRoute = (to, next) => {
  if (localStorage.getItem('token') || sessionStorage.getItem('token')) {
    if (localStorage.getItem('workspaceId')) {
      return next({ name: 'application' })
    } else {
      return next({ name: 'SelectWorkspace' })
    }
  } else {
    return next()
  }
}

const hasMiddleware = (to) => !!to.meta.middleware

const applyMiddleware = (to, from, next) => {
  const middleware = to.meta.middleware
  const context = { to, from, next }

  if (middleware[0]) {
    middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
    })
  } else {
    return next()
  }
}

export default router
